<template>
  <div class="w-100">
    <v-row align="center" justify="space-between">
      <v-col cols="6">
        <v-btn
          class="productFiltersBtn text-capitalize rounded-sm"
          outlined
          @click="dialog = true"
        >
          <v-icon color="primary">$filter</v-icon>
          <span> {{ $tc("filter.filters", getFacetsLength) }}</span>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <ProductSortFilter
          class="text-capitalize rounded-sm font-weight-bold text-body-2"
          :sortFilter="sortFilter"
          @handleSortFilter="handleSortFilter"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      hide-overlay
      fullscreen
      class="facet-dialog"
    >
      <v-row class="top-bar d-flex justify-end align-center">
        <v-btn
          plain
          small
          @click="discard()"
          class="my-2 text-capitalize discard"
        >
          <span>{{ $t("filter.button.discard") }}</span>
          <v-icon small class="ml-1 filter-close">$close</v-icon>
        </v-btn>
      </v-row>
      <div class="list">
        <v-row
          no-gutters
          class="d-flex pl-1 mb-2 filter-row"
          justify="space-between"
          align="center"
        >
          <span class="font-weight-bold"
            ><v-icon color="promo" class="mr-3">$percent</v-icon
            >{{ $t("filter.promo") }}</span
          >
          <v-switch
            :input-value="promoFilter"
            inset
            dense
            @change="handlePromoFilter"
            hide-details
            class="my-0"
            color="primary"
          >
          </v-switch>
        </v-row>

        <v-row
          no-gutters
          class="d-flex pl-1 filter-row"
          justify="space-between"
          align="center"
        >
          <span class="font-weight-bold"
            ><v-icon color="yellow" class="mr-3">$ratingEmpty</v-icon
            >{{ $t("filter.new") }}</span
          >
          <v-switch
            :input-value="newFilter"
            inset
            dense
            @change="handleNewFilter"
            hide-details
            class="my-0"
            color="primary"
          ></v-switch>
        </v-row>
        <v-expansion-panels multiple flat v-model="panel">
          <v-expansion-panel v-for="facet in facetsFiltered" :key="facet.name">
            <v-expansion-panel-header class="pl-10">
              <span class="text-body-2 font-weight-bold">{{ facet.name }}</span>
              <div
                class="ml-2 text-caption"
                v-if="
                  facetsFilter[facet.parameterName] &&
                    facetsFilter[facet.parameterName].length > 0
                "
              >
                {{
                  $tc(
                    "filter.filters",
                    facetsFilter[facet.parameterName].length
                  )
                }}
              </div>
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters class="d-flex flex-wrap">
                <v-checkbox
                  class="pr-2 w-50 d-flex align-center"
                  multiple
                  hide-details
                  v-for="value in facet.values"
                  :key="value.id"
                  :value="value.id"
                  :disabled="value.filteredCount == 0"
                  v-model="selectedFacetsXS[facet.parameterName]"
                  :label="value.name + ' (' + value.filteredCount + ')'"
                  @click="handleFacetFilterMobile()"
                ></v-checkbox>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-row
        no-gutters
        class="pa-1 bottom-bar"
        align="start"
        justify="space-around"
      >
        <v-btn
          color="primary"
          class="w-45"
          v-on:click="applyFilters"
          depressed
          :loading="loadingApplyButton"
        >
          {{ $tc("filter.button.applyShowAllProducts", filteredProductsCount) }}
        </v-btn>
        <v-btn
          v-on:click="clearAllFilters"
          depressed
          class="filter-button w-45"
        >
          {{ $t("filter.button.reset") }}
        </v-btn>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter";
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "ProductFacetFilterMobile",
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  components: {
    ProductSortFilter
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" },
    sortFilter: { type: Object }
  },
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter"
    }),
    // promoFilter: {
    //   get() {
    //     return this.storedPromoFilter;
    //   },
    //   set(promo) {
    //     return promo;
    //   }
    // },
    // newFilter: {
    //   get() {
    //     return this.storedNewFilter;
    //   },
    //   set(isNew) {
    //     return isNew;
    //   }
    // },
    getFacetsLength() {
      // const reducedFacets = Object.keys(this.selectedFacetsXS).reduce
      let facetsLength = 0;
      for (const prop in this.selectedFacetsXS) {
        facetsLength += this.selectedFacetsXS[prop].length;
      }
      return facetsLength;
    },
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
      this.loadingApplyButton = true;
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
      this.loadingApplyButton = true;
    },
    handleFacetFilterMobile() {
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
      this.loadingApplyButton = true;
    },
    applyFilters() {
      console.log(this.selectedFacetsXS);
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.selectedFacetsXS);
      this.dialog = false;
      this.panel = [];
    },
    clearAllFilters() {
      this.$emit("handleFacetFilterMobile", {});
      this.initialFacets = {};
      this.dialog = false;
      this.panel = [];
    },
    discard() {
      this.$emit("handleFacetFilterMobile", this.initialFacets);
      this.dialog = false;
      this.panel = [];
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
$buttons-row-height: 60px;
.top-bar {
  height: $buttons-row-height;
  .discard {
    font-size: 0.9rem !important;
  }
  .filter-close {
    font-size: 18px !important;
  }
}
.bottom-bar {
  height: $buttons-row-height;
  position: absolute;
  bottom: 0;
}
.list {
  height: calc(100vh - (#{$buttons-row-height} * 2));
  overflow-y: auto;
  .filter-row {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      line-height: 3;
    }
  }
}
.v-expansion-panel-content__wrap {
  padding: 0 40px 16px;
}
</style>
