<template>
  <v-select
    :items="sortOptions"
    :value="sortFilter"
    @change="handleSortFilter"
    hide-details
    :label="$vuetify.breakpoint.smAndDown ? $t('products.orderBy') : ''"
    :prefix="$vuetify.breakpoint.smAndDown ? '' : $t('products.orderBy')"
    dense
    :outlined="$vuetify.breakpoint.xs"
    :class="$vuetify.breakpoint.xs ? '' : 'ps-4 pt-0'"
    height="36"
    ><template v-slot:prepend-inner
      ><v-icon color="primary" class="d-flex d-sm-none mx-1"
        >$sort</v-icon
      ></template
    >
    <template v-slot:append
      ><v-icon color="primary" class="d-none d-sm-flex"
        >$expand</v-icon
      ></template
    >
  </v-select>
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg descrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "alf_asc",
          text: "Alfabetico crescente",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: { sortFilter: { type: Object } },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  }
};
</script>

<style lang="scss">
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-input__control {
    min-height: auto !important;
    .v-label {
      color: $text-color;
      letter-spacing: 1.1px;
      // top: 8px !important;
    }
  }
}
</style>
