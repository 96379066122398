<template>
  <ProductFacetFilterMobile
    v-if="isMobile"
    :facets="facets"
    :facetsFilter="facetsFilter"
    :promoFilter="promoFilter"
    :newFilter="newFilter"
    :sortFilter="sortFilter"
    :filteredProductsCount="filteredProductsCount"
    :hideFilterString="hideFilterString"
    @handlePromoFilter="handlePromoFilter"
    @handleNewFilter="handleNewFilter"
    @handleFacetFilterMobile="handleFacetFilterMobile"
    @handleSortFilter="handleSortFilter"
  />

  <ProductFacetFilterDesktop
    v-else
    :facets="facets"
    :facetsFilter="facetsFilter"
    :sortFilter="sortFilter"
    :hideFilterString="hideFilterString"
    :filteredProductsCount="filteredProductsCount"
    @handleFacetFilter="handleFacetFilterMobile"
    @handlePromoFilter="handlePromoFilter"
    @handleNewFilter="handleNewFilter"
    @handleSortFilter="handleSortFilter"
    :productsQty="productsQty"
  />
</template>

<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop.vue";
export default {
  name: "ProductFilters",
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    // eslint-disable-next-line vue/no-unused-components
    ProductSortFilter
  },
  props: {
    promoFilter: { type: Boolean },
    newFilter: { type: Boolean },
    facets: { type: Array },
    facetsFilter: { type: Object },
    sortFilter: { type: Object },
    filteredProductsCount: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    productsQty: { type: Number }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    },
    handleFacetFilter(filters) {
      this.$emit("handleFacetFilter", filters);
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$emit("handleFacetFilterMobile", selectedFacetsXS);
    }
  }
};
</script>

<style></style>
